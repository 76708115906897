import {camelCase} from "change-case";

let klass = {

    initElements(baseElement)
    {
        // Loop Vue components.
        for(let [tag, componentImport] of Object.entries(window.Vue.options.components))
        {
            // Loop relevant elements and init components.
            $(tag, baseElement || window)
                .each((index, element) => this.initVue(element, componentImport));
        }
    },

    initVue(element, componentImport)
    {
        var vueComp = Vue.extend(componentImport);

        new vueComp({
            propsData: this.getProps(element),
        }).$mount(element)
    },

    getProps(element)
    {
        let props = {};

        // Loop attributes.
        for (var i = 0; i < element.attributes.length; i++)
        {
            var attrib = element.attributes[i];

            let name = attrib.name;
            let value = attrib.value;

            // Parse JS?
            if(name.startsWith(':')) {
                name = name.substr(1);
                value = JSON.parse(value);
            }

            // Set
            props[camelCase(name)] = value;
        }

        //
        return props;
    }

}

// Export as function.
export default klass.initElements.bind(klass);