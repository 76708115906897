/**
 * Creates a object for every .components-form-Time DOM element.
 */
common.View.create('common.form.inputs.Oldfile', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        // Needed for website page block editor, which most of the time won't load the preview of an image.
        setTimeout(() => {
            this.initGui();
            this.initMultipleUpload();
            this.initSweetalert();
        }, 1);
        this.initDeleteFile();
    },

    initGui() {
        // Create file upload on file field
        this.element
            .find('input[type="file"]')
            .dropify({
                messages: {
                    'default': 'Drag en drop een file of klik om een bestand te uploaden',
                    'replace': 'Drag en drop een file of klik om een bestand te vervangen',
                    'remove': 'Verwijderen',
                    'error': 'Het zier er naar uit dat er iets fout is gegaan'
                }
            })
            .end();
    },

    initMultipleUpload() {
        this.element.find('input[type="file"]')
            .on('change', (event) => {
                // Get current file target
                var target = event.currentTarget,
                    mainElement = this;

                // If multiple attribute is found
                if( $(target).attr('multiple') ) {
                    this.uploadMultipleFilesAjax(target, mainElement);
                }
            });
    },

    /**
     * Sweet alert options
     */
    initSweetalert() {

        $('.openSwal')

        // Enable sweetalert button (always)
            .prop('disabled', false)

            // Show preview in (sweet)alert
            .on('click', (event) => {
                // Get event target, file and extension
                var $this = $(event.currentTarget),
                    file = $this.data('file'),
                    extension = $this.data('extension');

                // Sweetalert popup
                Swal.fire({
                    html: file && (extension == 'application/pdf' || extension == 'pdf') ?
                        '<embed src="' + file + '" width="100%" height="500" />'
                        : '<img class="img-fluid mb-3" src="' + file + '" />',
                    heightAuto: false,
                    width: '95%'
                });
            });
    },

    initDeleteFile() {
        var drEvent = this.element
            .find('input[type="file"]')
            .dropify();

        drEvent.on('dropify.afterClear', function(event, element){
            $('#removeFile').val('');
        });
    },

});