/**
 * Creates a object for every .common-canvas-blocks-custom-partners-Block DOM element.
 */
common.View.create('common.canvas.blocks.custom.partners.Block', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initSlider();
    },

    initSlider()
    {
        this.element.find('.partnerGrid')
            .owlCarousel({
                margin: 15,
                loop: true,
                nav: false,
                dots: false,
                autoplay: true,
                responsive : {
                    // breakpoint from 0 up
                    0 : {
                        items: 2,
                    },
                    // breakpoint from 768 up
                    768 : {
                        items: 3,
                    },
                    // breakpoint from 1024 up
                    1024 : {
                        items: 5,
                    }
                }
            });
    }

});