/**
 * Creates a object for every .components-form-Daterange DOM element.
 */
common.View.create('habitats.website.modules.teachingmaterial.Index', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        // this.initFiltering();
    }

});