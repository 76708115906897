common.View.create('common.form.inputs.Slug', {

	/**
	 * Called when DOM is ready.
	 */
	onDOMLoad() {
		this.initSlugPrefix();
		this.initGui();
		this.lockSwitcher();
	},

	initSlugPrefix() {
		var dataPrepend = this.element.find('.input-group').data('prepend');

		if( dataPrepend ) {
			this.element.find('input').val(dataPrepend + this.element.find('input').val());
		}
	},

	/**
	 * Initializes the GUI.
	 *
	 * @TODO Refactor?
	 */
	initGui() {
		// Data prepend on input?
		var dataPrepend = this.element.find('.input-group').data('prepend');

		this.element.find('input').each( (key, value) => {
			// Get current input value
			var $currentValue = $(value).val();

			// When user is typing
			$(value).add('input[name*="' + $(value).data('trigger') + '"]').on('keyup', (event) => {
				// if input is not readonly
				if( $(value).prop('readonly') && $currentValue && !dataPrepend ) {
					return;
				}

				// If lock input is readonly or if slug isn't a locked input
				if ($(event.currentTarget).prop('readonly') !== true || !$('button[name="lockSwitcher"]').length) {
					var addVal = '';

					// Check if prepend is added, if so don't edit if user doesn't specifically do so
					if( dataPrepend && !$(event.currentTarget).data('trigger') ) {
						addVal = dataPrepend;
					} else if( $(event.currentTarget).data('trigger') ) {
						this.element.find('.input-group').attr('data-edited', 1);
					}

					// Check if trigger is being edited while slug is not allowed to
					if( this.element.find('.input-group').data('edited') && !$(event.currentTarget).data('trigger') ) {
						return;
					}

					// Slugify value?
					if ($(value).data('slugify'))
						$(value).val(addVal + this.slugify(event.currentTarget.value));
					else
						$(value).val(addVal + event.currentTarget.value);
				}
			});

			// When user is typing
			$(value, 'input[name*="' + $(value).data('trigger') + '"]').on('blur', (event) => {
				$(value).val(event.currentTarget.value.replace(/\/$/g, ''));
			});

		});
	},

	slugify(str)
	{
		str = str.replace(/^\s+|\s+$/g, '');

		// Make the string lowercase
		str = str.toLowerCase();

		// Remove dirs?
		if(!this.element.data('allow-dir'))
			str = str.replace(/\//g, '-');

		// Remove accents, swap ñ for n, etc
		var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
		var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa-/----";
		for (var i=0, l=from.length ; i<l ; i++) {
				str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		// Remove invalid chars
		str = str.replace(/[^\w-\/ ]+/g, '')
			// Collapse whitespace and replace by -
			.replace(/\s+/g, '-')
			// Collapse dashes
			.replace(/-+/g, '-')
			// Replace multiple slashes
			.replace(/[/_,]+/g,'/')
			// And replace first and last slash with nothing
			.replace(/^\//g, '');

		return str;
	},

	lockSwitcher()
	{
		// Get the input and button
		var getInput = this.element.find('input');
		var getButton = this.element.find('button[name="lockSwitcher"]');

		// On lock button click
		getButton.click(() => {
			// Remove read only on click
			if(getInput.prop('readonly')) {
				getInput.removeAttr('readonly');
			} else {
				getInput.attr('readonly');
			}
		});
	},

});
