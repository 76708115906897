<template>

    <div>

        <WebsitePageSelector
                ref="websitePageSelector"
                :value.sync="value"
                @update:value="onValueUpdate"
                language="nl" />

        <input
                ref="hiddenInput"
                type="hidden"
                :name="name"
                v-model="value" />

    </div>

</template>

<script>

	import WebsitePageSelector from "../../../habitats/admin/common/WebsitePageSelector";

	export default {

        components: {
            WebsitePageSelector,
        },

        props: {
            name: String,
            initialValue: Number,
        },

        data()
        {
            return {
                value: this.initialValue,
            };
        },

        methods: {

            onValueUpdate()
            {
                // Trigger change event on hidden input. Needer for resources/js/habitats/admin/modules/website/websitePages/createEdit/moduleEditor/Options.vue which listens on input changes to determine if its save button must be enabled.
                $(this.$refs['hiddenInput']).trigger('change');
            },

        },

    }

</script>