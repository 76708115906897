/**
 * Creates a object for every .components-form-Time DOM element.
 */
common.View.create('common.form.inputs.File', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initDropzone();
        this.initGetAllFiles();
    },

    initDropzone() {
        var that = this;

        this.element
            .find('.dropzone')
            .dropzone({
                url: '/api/admin/upload-images',
                paramName: this.element.find('.dropzone').data('collection'),
                multiple: true,
                sending: (file, xhr, formData) => {
                    formData.append('id', this.element.find('.dropzone').data('id'));
                    formData.append('model', this.element.find('.dropzone').data('model'));
                    formData.append('collection', this.element.find('.dropzone').data('collection'));
                },
                success: function() {
                    // Get all files
                    that.initGetAllFiles();

                    // Set images as uploaded
                    this.element.find('input[name*="files"]').val(true);

                    // Re-init remove all files function
                    this.removeAllFiles();
                },
                ...this.initGetDropzoneOptions()
            })
            .end();
    },

    initGetDropzoneOptions()
    {
        return {
            dictDefaultMessage: 'Sleep of klik uw bestand hier om te uploaden',
            dictFallbackMessage: 'Bestand uploaden',
            dictFallbackText: 'U kunt hier uw bestand uplaoden',
            dictFileTooBig: 'Bestand is te groot',
            dictInvalidFileType: 'Geen geldig type',
            dictResponseError: 'Response error',
            dictCancelUpload: 'Annuleren',
            dictUploadCanceled: 'Geanuleerd',
            dictCancelUploadConfirmation: 'Weet u zeker dat u de upload wilt annuleren?',
            dictRemoveFile: 'Verwijderen',
            dictRemoveFileConfirmation: 'Weet u zeker dat u het bestand wilt verwijderen?',
            dictMaxFilesExceeded: 'Maximum bestanden overschreden'
        };
    },

    initGetAllFiles() {
        // Get item id
        var id = this.element.find('.dropzone').data('id'),
            model = this.element.find('.dropzone').data('model'),
            collection = this.element.find('.dropzone').data('collection');

        // Check if item exists; if so then check all images that belong to that item
        $.get('/api/admin/receive-images/?model=' + model + '&id=' + id + '&collection=' + collection)
            .done((item) => {
                // Remove all images
                this.element.find('.imgBox').remove();

                // Render images
                $.each(item.paths, (imgKey, imgValue) => {
                    let imgValueSplit = imgValue.split('/');

                    this.element.find('.dropzoneFiles')
                        .append('<div class="imgBox">' +
                            '<img src="' + imgValue + '" ' +
                            'data-id="' + (item.collection[imgKey] ? item.collection[imgKey].id : '') + '" ' +
                            'data-name="' + (item.collection[imgKey] ? item.collection[imgKey].name : imgValue) + '"' +
                            'data-extension="' + (item.collection[imgKey] ? item.collection[imgKey].mime_type : '') + '">' +
                            '<span>' +
                            //(item.collection[imgKey] ? item.collection[imgKey].name : imgValueSplit[imgValueSplit.length - 1]) +
                            '</span>' +
                            '</div>');
                });

                this.initSweetAlert();
            });
    },

    initSweetAlert() {
        this.element.find('.dropzoneFiles .imgBox')
            .on('click', (event) => {
                // Get file information
                var file = $(event.currentTarget).find('img'),
                    fileSrc = file.attr('src'),
                    fileExt = file.data('extension');

                // Sweetalert settings
                Swal.fire({
                    html: fileExt == 'application/pdf' ?
                        '<embed src="'+ fileSrc +'" width="100%" height="500" />'
                        : '<img class="img-fluid mb-3" src="'+ fileSrc +'" />',
                    ...this.initGetSwalOptions()
                }).then((result) => {
                    // Delete image from server?
                    if (result.value) {
                        this.deleteImageFromServer(file);
                    }
                });
            });

    },

    initGetSwalOptions()
    {
        return {
            heightAuto: false,
            width: '75%',
            showCancelButton: true,
            confirmButtonColor: '#f62d51',
            cancelButtonColor: '#7460ee',
            cancelButtonText: '<i class="fa fa-times"></i> Cancel',
            confirmButtonText: '<i class="fa fa-trash"></i> Delete it!'
        };
    },

    deleteImageFromServer(image)
    {
        // Get item number
        var model = this.element.find('.dropzone').data('model');

        // Set form data
        var formData = new FormData();
        formData.append('model', model);
        formData.append('name', image.data('name'));
        formData.append('image', image.data('id'));

        // Remove from server
        $.ajax({
            type: 'POST',
            url: '/api/admin/delete-images',
            data: formData,
            processData: false,
            contentType: false,
        }).done(() => {
            image.parents('.imgBox').remove();
        });
    }

});
