import Swal from 'sweetalert2';
import mountVues from '../../mountVues';

common.View.create('common.form.dataset.Index', {

    onDOMLoad()
    {
        this.initGui();
        this.initSorting();
        this.updateIndices();
    },

    initGui()
    {
        this.element

            // Listen for delete button click.
            .find('button[name="delete"]')
                .click(this.onButtonDeleteClick.bind(this))
                .end()

            // Listen for add button click.
            .find('button[name="add"]')
                .click(this.onButtonAddClick.bind(this))
                .end()

            .find('.card-title')
                .click(this.onTitleClick)
                .end();
    },

    initSorting()
    {
        // Get items element.
        var itemsElement = this.element.find('.dataset-items').get(0);

        // Init sortable items.
        window.sortable(itemsElement, {
            items: '.dataset-item:not(.new)',
            handle: 'button[name="sort"]',
            forcePlaceholderSize: true,
            placeholder: this.buildSortingPlaceholder(),
        });

        // Update indices once sorting is stopped.
        itemsElement.addEventListener('sortstop', this.updateIndices.bind(this));
    },

    reinitSorting()
    {
        // Reinit - options are preserved.
        window.sortable(this.element.find('.dataset-items').get(0));
    },

    buildSortingPlaceholder()
    {
        // Get html of .dataset-items.new without content.
        return this.element

            .find('.dataset-item.new')
                .clone()
                .removeClass('d-none new')
                .html(this.getType() == 'table' ? '<td></td>' : '')
                .wrap('<div />')
                .parent()
                .html();
    },

    async onButtonDeleteClick(event)
    {
        // Confirm
        const { value: confirmed } = await Swal.fire({
            title: 'Weet u het zeker?',
            text: 'Weet u zeker dat u deze wenst te verwijderen?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ja, verwijder',
            cancelButtonText: 'Annuleer',
        });

        // Is confirmed?
        if(confirmed)
            this.deleteItem($(event.currentTarget).closest('.dataset-item'));
    },

    onTitleClick(event)
    {
        $(event.currentTarget)

            .parent()
                .find('.dataset-content')

                    // Show and hide content
                    .slideToggle(function() {
                        $(this).parent().find('.card-title .drop-toggle i')
                            .removeClass()
                            .addClass( $(this).is(':hidden') ? 'fa fa-caret-right' : 'fa fa-caret-down' );
                    })
                    .end();
    },

    onButtonAddClick()
    {
        // Get new template.
        let newRow = this.element

            // Find new item.
            .find('.dataset-item.new')

                // Clone
                .clone(true, true)
                .removeClass('d-none new')

                // Hide
                .css({
                    display: 'none',
                    opacity: 0
                })

                // Append to items.
                .appendTo(this.element.find('.dataset-items'))

                // Animate in.
                .slideDown('slow')
                .animate({ opacity: 1 }, 'slow');

        //
        this.initRowWithTemplate(newRow);

        // Add row to items.
        newRow.appendTo(this.element.find('.dataset-items'));

		mountVues(newRow);

        //
        this.updateIndices();
        this.updateAddButton();
        this.reinitSorting();

        //
        common.View.reinit();
    },

    initRowWithTemplate(newRow)
    {
        // Get template.
        let template = this.element.find('.dataset-item.new noscript[data-new]').text();

        // TODO Why needed sometimes and sometimes not?
        // Remove script wrapper?
        if(template.startsWith('<script type="text/html">'))
            template = template.substr('<script type="text/html">'.length, template.length - '</script>'.length);

        // Table?
        if(this.getType() == 'table')
            newRow

                // Replace
                .find('.newPlaceholder')
                    .replaceWith(template)
                    .end()

        // Card?
        else
            newRow

                // Init fields.
                .find('noscript[data-new]')
                    .replaceWith(template)
                    .end();
    },

    getType()
    {
        return this.element.find('.common-form-dataset-ItemsTable').length == 1 ? 'table' : 'card';
    },

    updateAddButton()
    {
        // Abort due to max amount of items is not limited?
        let maxItemsAmount = this.element.find('.dataset-items').data('max-items-amount');

        if(!maxItemsAmount)
            return;

        // Determine amount of items.
        let disabled = this.element.find('.dataset-item:not(.new)').length >= maxItemsAmount;

        //
        this.element

            // Update add button.
            .find('button[name="add"]')
                .prop('disabled', disabled)
                .attr('disabled', disabled)
                .css('pointer-events', disabled ? 'none' : 'auto')

                .parent()

                    // (Un)set tooltip data.
                    .attr({
                        'data-toggle': disabled ? 'tooltip' : '',
                        'data-original-title': '',
                        'title': disabled ? 'Het maximum aantal van ' + maxItemsAmount + ' is reeds bereikt.' : ''
                    })

                    // Reinit tooltip.
                    .tooltip('dispose')
                    .tooltip()

                    .end()

                .end();
    },

    deleteItem(itemElement)
    {
        itemElement

            // Disable interaction.
            .css('pointer-events', 'none')

            // Hide with animation.
            .animate({ opacity: 0 }, 'slow')
            .slideUp('slow', () =>
            {
                // Remove
                itemElement.remove();

                // Update
                this.updateIndices();
                this.updateAddButton();
            });
    },

    updateIndices()
    {
        this.element

            // Loop items.
            .find('.dataset-item:not(.new)')
                .each((index, element) => this.updateIndicesItem($(element), index))
                .end();
    },

    updateIndicesItem(itemElement, itemIndex)
    {
        itemElement

            // Update iteration.
            .find('.iteration:first')
                .text(itemIndex + 1)
                .end()

            // Loop all inputs.
            .find('input, select, textarea')
                .filter('[name]')
                    .each((index, element) => this.updateIndicesInput(itemElement, $(element), itemIndex))
                    .end();
    },

    updateIndicesInput(itemElement, inputElement, itemIndex)
    {
        // Get current name.
        let postfixName = inputElement.attr('name');

        // Abort due to is internal __dataset item?
        if(postfixName.startsWith('__dataset['))
            return;

        // Loose dataset name + "[".
        let datasetName = itemElement.data('name');

        postfixName = postfixName.substr(datasetName.length + 1);

        // Get current index.
        let currentIndex = postfixName.split(']')[0];

        // Loose index + "]".
        postfixName = postfixName.substr((currentIndex + '').length + 1) ;

        // Set name.
        inputElement.attr('name', datasetName + '[' + itemIndex + ']' + postfixName);
    }

});
