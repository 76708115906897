// TODO Review
$(() => {

    // Set CSRF token as AJAX header.
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
    });

    // Confirm alert
    $('body').on('click', '[data-confirm]', function(event){
        event.preventDefault();

        Swal.fire({
            title: $(this).data('confirm'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            confirmButtonColor: '#55ce63',
            cancelButtonText: 'Annuleren'
        }).then((result) => {

            if(result.value)
                $(event.currentTarget).parent().submit();

        });

    });

});