/**
 * Creates a object for every .components-form-Daterange DOM element.
 */
common.View.create('habitats.website.modules.teachingmaterial.Show', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initRemoveDates();
        this.initSteps();
    },

    initRemoveDates()
    {
        $('.remove-dates', this.element)
            .on('click', (event) => {
                $('select[name="amount"] option:not(:disabled)', this.element).remove();
            });
    },

    initSteps()
    {
        this.element
            .find('input[name="reserved_from"]')
                .on('change', (event) => {
                    // Set check mark for start date
                    let $el = this.element.find('.reservation .information ol li:first-child span');

                    if( $(event.currentTarget).val() )
                        $el.html('<i class="fas fa-check"></i>');
                    else
                        $el.html('');

                    setTimeout(() => {

                        // New date selection? Set amount disabled
                        this.element.find('[name="amount"]')
                            .find('option:not([disabled])')
                                .remove()
                                .end()
                            .css('disabled', true)
                            .val('').change();

                        // Ajax call on new start date select
                        if(
                            this.element.find('input[name="reserved_from"]').val() &&
                            this.element.find('input[name="reserved_to"]').val()
                        ) {
                            $.ajax({
                                url: $(event.currentTarget).parents('form').attr('action'),
                                type: 'PUT',
                                data: $(event.currentTarget).parents('form').serialize(),
                                success: (data) => {
                                    for (let i = 1; i <= data; i++) {
                                        if( !this.element.find('[name="amount"] option[value="'+ i + '"]').length ) {
                                            // Add new options
                                            this.element.find('[name="amount"]')
                                                .append($('<option>').attr('value', i).text(i))
                                                .val('').change();
                                        }
                                    }
                                }
                            });
                        }

                    }, 1);
                })
                .end()
            .find('input[name="reserved_to"]')
                .on('change', (event) => {
                    // Set check mark for end date
                    let $el = this.element.find('.reservation .information ol li:nth-child(2) span');

                    if( $(event.currentTarget).val() )
                        $el.html('<i class="fas fa-check"></i>');
                    else
                        $el.html('');

                    setTimeout(() => {
                        // Get amount field
                        this.element.find('[name="amount"]')
                            // Remove disabled from amount
                            .css('disabled', false)

                            // Remove current options
                            .find('option:not([disabled])')
                                .remove()
                                .end()

                            .end();

                        $.ajax({
                            url: $(event.currentTarget).parents('form').attr('action'),
                            type: 'PUT',
                            data: $(event.currentTarget).parents('form').serialize(),
                            success: (data) => {
                                for (let i = 1; i <= data; i++) {
                                    if (!this.element.find('[name="amount"] option[value="' + i + '"]').length) {
                                        // Add new options
                                        this.element.find('[name="amount"]')
                                            .append($('<option>').attr('value', i).text(i))
                                            .val('').change();
                                    }
                                }
                            }
                        });

                    }, 1);
                })
                .end()
            .find('[name="amount"]')
                .css('disabled', true)
                .on('change', (event) => {
                    let $el = this.element.find('.reservation .information ol li:last-child span');

                    if( $(event.currentTarget).val() )
                        $el.html('<i class="fas fa-check"></i>');
                    else
                        $el.html('');
                })
                .end();
    }

});