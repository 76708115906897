/**
 * Creates a object for every .components-form-Daterange DOM element.
 */
common.View.create('common.form.inputs.Datetime', {

    options: {
        singleDatePicker: true,
        showDropdowns: true,
        timePicker: true,
        timePicker24Hour: true,
        autoUpdateInput: false,
        locale: {
            format: 'DD-MM-YYYY H:mm',
            "separator": " - ",
            "applyLabel": "Toepassen",
            "cancelLabel": "Annuleren",
            "fromLabel": "Van",
            "toLabel": "Tot",
            "daysOfWeek": [
                "Zo",
                "Ma",
                "Di",
                "Wo",
                "Do",
                "Vr",
                "Za"
            ],
            "monthNames": [
                "Januari",
                "Februari",
                "Maart",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Augustus",
                "September",
                "Oktober",
                "November",
                "December"
            ],
            "firstDay": 1
        },
    },

    /**
     * Called when DOM is ready.
     */
    onDOMLoad()
    {
        this.initOptions();
        this.initDatepicker();
    },

    initOptions()
    {
        // Set seconds on daterange
        if( this.element.find('input').data('seconds') ) {
            this.options.timePickerSeconds = true;
            this.options.locale.format = 'DD-MM-YYYY H:mm:ss';
        }
    },

    initDatepicker()
    {
        $('input', this.element).daterangepicker(this.options);

        $('input', this.element).on('apply.daterangepicker', (ev, picker) => {
            $(picker.element).val(picker.startDate.format(this.options.locale.format));
        });

        $('input', this.element).on('cancel.daterangepicker', (ev, picker) => {
            $(picker.element).val('');
        });
    },

});
