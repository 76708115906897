/**
 * Creates a object for every .components-form-Daterange DOM element.
 */
common.View.create('common.form.inputs.Daterange', {

    maxDate: [],
    onlyShowDays: [],
    minStartDays: null,
    onlyShowStartDays: null,
    onlyShowEndDays: null,
    disabledDates: [],
    maxDays: null,
    maxWeeks: null,

    options: {
        showDropdowns: true,
        language: 'nl',
        regional: 'nl',
        locale: {
            format: 'DD-MM-YYYY',
            hiddenFormat: 'YYYY-MM-DD',
            applyLabel: "Toepassen",
            cancelLabel: "Annuleren",
        },
        autoUpdateInput: false,
    },

    inlineOptions: {
        minDate: 0,
        numberOfMonths: [2, 1],
    },

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initLocale();
        this.initOptions();
        this.initDatepicker();
        this.initEmptyDatepicker();
    },

    initLocale() {
        $.datepicker.regional.nl = {
            closeText: "Sluiten",
            prevText: "←",
            nextText: "→",
            currentText: "Vandaag",
            monthNames: ["januari", "februari", "maart", "april", "mei", "juni",
                "juli", "augustus", "september", "oktober", "november", "december"],
            monthNamesShort: ["jan", "feb", "mrt", "apr", "mei", "jun",
                "jul", "aug", "sep", "okt", "nov", "dec"],
            dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
            dayNamesShort: ["zon", "maa", "din", "woe", "don", "vri", "zat"],
            dayNamesMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
            weekHeader: "Wk",
            dateFormat: "dd-mm-yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        };

        $.datepicker.setDefaults($.datepicker.regional.nl);
    },

    initOptions() {
        // Save max date options from input
        this.maxDate = this.element.data('maxdate').split('|');
        this.minDays = this.element.data('mindays');
        this.minStartDate =  new Date(new Date().getTime() + (+this.element.data('minStartDays') * 24 * 60 * 60 * 1000));
        this.onlyShowDays = this.element.data('showdays');
        this.onlyShowStartDays = this.element.data('startday');
        this.onlyShowEndDays = this.element.data('endday');
        this.maxDays = this.element.data('maxdays');
        this.maxWeeks = this.element.data('maxweeks');

        // Get current date
        let date = new Date();
        this.getDisabledDatesForMonths(date.getFullYear(), date.getMonth());

        // Set inline datepicker options
        this.inlineOptions = Object.assign(this.inlineOptions, this.initInlineDatepicker());

        // Set time on daterange
        if (this.element.find('input').data('time') || this.element.find('input').data('timeseconds')) {
            this.options.timePicker = true;
            this.options.timePicker24Hour = true;
            this.options.locale.format = 'DD-MM-YYYY h:mm';
            this.options.locale.hiddenFormat = 'YYYY-MM-DD h:mm:ss';
        }

        // Set seconds on daterange
        if (this.element.find('input').data('seconds') || this.element.find('input').data('timeseconds')) {
            this.options.timePickerSeconds = true;
            this.options.locale.format = 'DD-MM-YYYY h:mm:ss';
            this.options.locale.hiddenFormat = 'YYYY-MM-DD h:mm:ss';
        }
    },

    initDatepicker() {
        // Get inline date pickers
        $('.datepicker', this.element)
            .datepicker(this.inlineOptions);

        // Get date pickers
        $('input[name=""]', this.element)
            .daterangepicker(this.options)
            .on('input apply.daterangepicker', this.onChange.bind(this));
    },

    initInlineDatepicker() {
        return {
            beforeShowDay: (date) => {
                // Set fallback class for inputs
                let fallBackClass = '',
                    additionalClass = '';

                // Get dates
                let date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $('.input1', this.element).val());
                let date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $('.input2', this.element).val());

                // Get in between dates as selected
                let inBetweenDates = date1 && ((date.getTime() === date1.getTime()) || (date2?.getTime() && date.getTime() >= date1.getTime() && date.getTime() <= date2?.getTime()));

                // Get days that only need to be shown
                let onlyShowDays = true;
                if (this.onlyShowDays || this.onlyShowStartDays || this.onlyShowEndDays) {
                    let onlyShowDays = false;

                    if (this.onlyShowDays) {
                        for (let d = 0; d < this.onlyShowDays.length; d++) {
                            if (this.onlyShowDays[d] === date.getDay()) {
                                onlyShowDays = true;
                                break;
                            }
                        }
                    }
                    // Get date
                    let formattedDate = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

                    // Check if date is within minimum lenght of days. Add minDays to the date1
                    minDate = new Date(date1);
                    minDate.setDate(minDate.getDate() + (this.minDays || 0));

                    // Check for minStartdate and minDate on ddate
                    if (date1 && (
                        this.minStartDate.getTime() > date.getTime()
                        || (
                            date.getTime() > minDate.getTime()
                            && minDate.getTime() > date.getTime()
                            && date1.getTime() !== date.getTime()
                        )
                    ) ) {
                        //onlyShowDays = false;
                        return [false, inBetweenDates ? "dp-highlight" : ''];
                    }

                    // Do the same in reverse. When we have selected a date which could be the end date
                    // Check if from the end date calculating the minimal start date is still valid
                    if (date1 && !date2) {
                        let minmumDateFromEndDate = new Date(date1);
                        minmumDateFromEndDate.setDate(minmumDateFromEndDate.getDate() - (this.maxDays || 0));
                        minmumDateFromEndDate.setDate(minmumDateFromEndDate.getDate() - (this.maxWeeks || 0) * 7);

                        if (minmumDateFromEndDate > date) {
                            // onlyShowDays = false;
                            return [false, inBetweenDates ? "dp-highlight" : ''];
                        }
                    }
                    
                    // Only show specific dates on selection?
                    onlyShowDays = !!(
                        // Only Start date selection
                        (!date1 && !date2 && this.onlyShowStartDays && this.onlyShowStartDays == date.getDay())

                        // Only End date selection
                        || (date1 && this.onlyShowEndDays 
                            // Check if this.onlyShowEndDays is an array, then check if the day is in the array. Otherwise check if the day is the same as the onlyShowEndDays
                            && (Array.isArray(this.onlyShowEndDays) 
                                ? this.onlyShowEndDays.includes(date.getDay()) 
                                : this.onlyShowEndDays === date.getDay()
                            )
                        )

                        // Both dates already selected
                        || date1 && date2 && (this.onlyShowStartDays && this.onlyShowStartDays === date.getDay() 
                            // Check if this.onlyShowEndDays is an array, then check if the day is in the array. Otherwise check if the day is the same as the onlyShowEndDays
                            || (Array.isArray(this.onlyShowEndDays)
                                ? this.onlyShowEndDays.includes(date.getDay())
                                : this.onlyShowEndDays === date.getDay()
                            )
                        )
                    );

                    // Check if is within disabled dates?
                    if( $.inArray(formattedDate, this.disabledDates) !== -1 ) {
                        fallBackClass = 'cp-booked'; additionalClass = 'cp-booked';
                    }

                    // Day was to be shown? But is in disabled dates?
                    if( onlyShowDays ) {
                        onlyShowDays = $.inArray(formattedDate, this.disabledDates) !== -1 ? false : true
                    }

                    // Set all items in between as selected
                    return [onlyShowDays, inBetweenDates ? "dp-highlight " + additionalClass : fallBackClass];
                }

                //
                return [true, inBetweenDates ? "dp-highlight " + additionalClass : fallBackClass];
            },
            onChangeMonthYear: (year, month) => {
                this.getDisabledDatesForMonths(year, month);
            },
            onSelect: (dateText, inst) => {
                this.initInlinePickerOnSelect(dateText, inst);
            }
        };
    },

    getDisabledDatesForMonths(year, month)
    {
        // Get first and last day of datepicker
        let firstDay = new Date(year, month - 1, 1),
            lastDay = new Date(year, month + 3, 0);

        // Get formatted dates
        let firstDayFormatted = firstDay.getFullYear() + '-' + firstDay.getMonth() + '-' + ('0' + firstDay.getDate()).slice(-2),
            lastDayFormatted = lastDay.getFullYear() + '-' + lastDay.getMonth() + '-' + ('0' + lastDay.getDate()).slice(-2);

        // Check if item is available at that time
        $.ajax({
            url: document.URL.replace(/#.*$/, "") + '/checkDate',
            type: 'PUT',
            async: false,
            data: 'reserved_from=' + firstDayFormatted + '&reserved_to=' + lastDayFormatted,
            success: (data) => {
                this.disabledDates = data;
            }
        });
    },

    initInlinePickerOnSelect(dateText, inst) {
        // Get start and end date
        let $startAt = $('.input1', this.element);
        let $endAt = $('.input2', this.element);

        // Parse dates
        let date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $startAt.val());
        let date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $endAt.val());
        let selectedDate = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dateText);

        this.initInlineSetMaxDate(dateText, inst, $startAt, $endAt, date1, date2, selectedDate);

        // Create date array; and get the closest date to the new selectedDate
        let arr = [date1, date2];
        arr.sort(function (a, b) {
            let distancea = Math.abs(selectedDate - a),
                distanceb = Math.abs(selectedDate - b);
            return distancea - distanceb; // sort a before b when the distance is smaller
        });

        this.initInlineDatepickerSetValues(dateText, inst, arr, $startAt, $endAt, date1, date2, selectedDate);

        // Re-init picker
        $(inst.input).datepicker();
    },

    initInlineSetMaxDate(dateText, inst, $startAt, $endAt, date1, date2, selectedDate)
    {
        // If a date is selected; prepare an end date based on user given input
        if ( this.maxDate.filter((el) => { return el && el !== '0'; }).length
            && ((selectedDate && !date1) || (date1 && !date2) || date1 !== selectedDate && date2 !== selectedDate)
        ) {
            // Convert selected day to a js date format
            let firstDay = null;

            // If start day is clicked, then change start day to that day
            if( this.onlyShowStartDays && this.onlyShowStartDays === selectedDate.getDay() )
                firstDay = new Date(selectedDate);

            // Otherwise check if selected date is before start date; then set selected date as first day
            // If not keep the first day as is
            else
                firstDay = new Date( selectedDate < date1 || !date1 ? selectedDate : date1 );

            // Get end day based on max date
            let lastDay = new Date(
                firstDay.getFullYear() + +this.maxDate[0], // Years
                firstDay.getMonth() + +this.maxDate[1], // Months
                firstDay.getDate() + +this.maxDate[2] * 7 + +this.maxDate[3], // Weeks and days
                firstDay.getHours() + +this.maxDate[4], // Hours
                firstDay.getMinutes() + +this.maxDate[5], // Minutes
                firstDay.getSeconds() + +this.maxDate[6] // Seconds
            );

            // Doesn't have start day or has start day but the user has selected a new valid start day
            if( !this.onlyShowStartDays ||
                !(selectedDate < date1 && date2 && selectedDate.getDay() !== this.onlyShowStartDays)
            ) {
                // Set max date
                $(inst.input).datepicker('option', 'maxDate', lastDay);

                // Remove user max date because selected last day is higher than allowed?
                if (lastDay < date2)
                    $endAt.val(lastDay.getDate() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getFullYear()).trigger('change');
            }
        }
    },

    initInlineDatepickerSetValues(dateText, inst, arr, $startAt, $endAt, date1, date2, selectedDate)
    {
        // Set values for inputs
        if (date1 && date2 && arr[0])
        {
            // Check if specific date needs to be selected for start and end date
            if (this.onlyShowStartDays || this.onlyShowEndDays) {
                // Check if newly selected date is not longer than the maximum date
                let maxDate = new Date(date1);
                maxDate.setDate(maxDate.getDate() + (this.maxWeeks || 0) * 7);
                maxDate.setDate(maxDate.getDate() + (this.maxDays || 0));

                // Check if clicked date is the end day. Check if this.onlyShowEndDays is an array, then check if the day is in the array. Otherwise check if the day is the same as the onlyShowEndDays
                if ( date1 
                    // Check if selected date is not after max date 
                    && selectedDate < maxDate
                    && (Array.isArray(this.onlyShowEndDays) 
                        ? this.onlyShowEndDays.includes(selectedDate.getDay()) 
                        : this.onlyShowEndDays === selectedDate.getDay()
                    ) && selectedDate > date1
                )
                    $endAt.val(dateText).trigger('change');

                // Check if clicked date is the starting day
                else if (this.onlyShowStartDays === selectedDate.getDay())
                    $startAt.val(dateText).trigger('change');

                // Current selected date is after end date and is a start date
                if( selectedDate > date2 && this.onlyShowStartDays === selectedDate.getDay() ) {
                    $endAt.val('');
                }
            } else {
                // Change closest clicked date to what the current date is
                if (date1 === arr[0])
                    $startAt.val(dateText).trigger('change');
                else
                    $endAt.val(dateText).trigger('change');
            }
        }

        // Set start date
        else if (!date1 || date2) {
            $startAt.val(dateText).trigger('change');
            $endAt.val("");
        }

        // Selected date is before start at?
        else if (selectedDate < date1) {
            // Date selection is not start date?
            if( !this.onlyShowStartDays || (selectedDate.getDay() === this.onlyShowStartDays) ) {
                $endAt.val($startAt.val()).trigger('change');
                $startAt.val(dateText).trigger('change');
            }
        }

        // Only end at changes
        else {
            if( !this.onlyShowEndDays || (this.onlyShowEndDays 
                && (Array.isArray(this.onlyShowEndDays)
                    ? this.onlyShowEndDays.includes(selectedDate.getDay())
                    : this.onlyShowEndDays === selectedDate.getDay()
                )
            ) ) {
                $endAt.val(dateText).trigger('change');
            }
        }
    },

    onChange(ev, picker)
    {
        this.updateVisibleInput(ev, picker);
        this.updateHiddenInputs(ev, picker);
    },

    updateVisibleInput(ev, picker)
    {
        $('input[name=""]', this.element)
            .val(picker ? picker.startDate.format(this.options.locale.format)
                            + ' - '
                            + picker.endDate.format(this.options.locale.format)
                : '');
    },

    updateHiddenInputs(ev, picker)
    {
        // Loop hidden inputs.
        _.each($('input:not([name=""])', this.element), (input, index) =>
        {
            // Set value?
            if(picker)
                $(input).val(picker[index == 0 ? 'startDate' : 'endDate'].format(this.options.locale.hiddenFormat));

            // Clear value?
            else
                $(input).val('');
        });
    },

    initEmptyDatepicker()
    {
        $('.remove-dates', this.element)
            .on('click', (event) => {
                // Empty dates
                $('.input1', this.element).val('').trigger('change');
                $('.input2', this.element).val('').trigger('change');

                // Re-init picker
                $('.datepicker', this.element)
                    .datepicker( "option" , {
                        maxDate: null
                    });
            })
    }

});