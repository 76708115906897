common.View.create('habitats.website.layouts.Main', {

    onDOMLoad() {
        this.initHeader();
    },

    initHeader()
    {
        $('.habitats-website-layouts-main-Header .slider')
            .owlCarousel({
                nav:false,
                autoplay:true,
                autoplayTimeout: 5000,
                autoplaySpeed: 1000,
                loop: true,
                items: 1
            });
    }

});
