<template>

    <div class="habitats-admin-common-WebsitePageSelector">

        <treeselect
                :value="internalValue"
                :options="treeOptions"
                clearAllText="Verwijder allemaal"
                clearValueText="Verwijder"
                loadingText="Laden.."
                noChildrenText="Geen pagina's"
                noOptionsText="Geen keuzes.."
                noResultsText="Geen keuzes.."
                retryText="Probeer opnieuw"
                searchPromptText="Typ om te zoeken"
                @input="onInput($event)">

            <template slot="option-label" slot-scope="{ node }">

                <div
                        v-if="node.raw.type == 'menu'"
                        class="text-sm"
                        :class="{
                            'font-bold': selectedParents.indexOf('websiteMenu-' + node.raw.websiteMenu.id) !== -1,
                            'py-1': true,
                        }">{{ node.raw.name }} menu</div>

                <div
                        v-else
                        :class="{
                            'bg-none': true,
                            'text-sm': true,
                            'text-blue-400': node.raw.websiteMenuPage.website_page.id == value,
                            'font-bold': node.raw.websiteMenuPage.website_page.id == value || selectedParents.indexOf('websitePage-' + node.raw.websiteMenuPage.website_page.id) !== -1,
                        }">{{ node.raw.title }}</div>

            </template>

            <div slot="value-label" slot-scope="{ node }">{{ node.raw.title }}</div>

        </treeselect>

    </div>

</template>

<script>

    import axios from 'axios';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {

        components: {
            Treeselect,
        },

        props: {
            value: Number,

            // Needed for CKEditor plugin.
            initialValueLink: String,
            language: String,
        },

        data()
        {
            return {
                internalValue: null,
                websitePages: null,
                websitePageId2internalId: {},
                id2websitePage: {},
                selectedParents: [],
            };
        },

        async mounted()
        {
            await this.loadData();

            this.initInitialValueLink();
        },

        methods: {

            async loadData()
            {
                // Load artists.
                this.websitePages = (await axios.get('/admin/website/website-page-selector/data')).data;
            },

            initInitialValueLink()
            {
                // Abort due to not present?
                if(!this.initialValueLink)
                    return;

                // Find website page.
                let websitePage = _.find(Object.values(this.id2websitePage), websitePage => '/' + websitePage.slug[this.language] == this.initialValueLink);

                // Emit new value?
                if(websitePage)
                    this.$emit('update:value', websitePage.id);
            },

            onInput(value)
            {
                // Extract website page id?
                if(value)
                {
                    // Clear due to no menu is selected?
                    if(!value.includes('-'))
                        value = undefined;

                    // Get id from website page item?
                    else
                        value = parseInt(value.split('_websitePage-').pop());
                }
                // Clear value due to none selected?
                else if(value === 0)
                    value = undefined;

                // Update value?
                if(this.value !== value)
                    this.$emit('update:value', value);
            },

            mapItem(websiteMenu, websiteMenuPages, parentIds = [])
            {
                // Abort due to no items? Otherwise Treeselect will show empty subtree.
                if(!websiteMenuPages.length)
                    return undefined;

                // Map items.
                return _.map(websiteMenuPages, websiteMenuPage => {

                    // Map in internal id - to support non-unique items.
                    let internalId = 'menu-' + websiteMenu.id + '_websitePage-' + websiteMenuPage.website_page.id;

                    this.websitePageId2internalId[websiteMenuPage.website_page.id] = internalId;

                    // Set url.
                    this.id2websitePage[websiteMenuPage.website_page.id] = websiteMenuPage.website_page;

                    // Add as selected parent?
                    if(this.value == websiteMenuPage.website_page.id)
                        this.selectedParents.push(...parentIds);

                    // Get title.
                    let title = websiteMenuPage.website_page.title_menu?.[this.language] || websiteMenuPage.website_page.title_page?.[this.language];

                    // Map
                    return {
                        id: internalId,
                        websiteMenuPage,
                        type: 'websitePage',
                        title,
                        label: title, // To support search.
                        children: this.mapItem(
                            websiteMenu,
                            websiteMenuPage.children,
                            [].concat(parentIds, ['websitePage-' + websiteMenuPage.website_page.id])
                        ),
                    };
                });
            },

        },

        computed: {

            treeOptions()
            {
                // Abort due to empty?
                if(this.websitePages === null)
                    return [];

                //
                this.selectedParents = [];
                this.id2websitePage = {};
                this.websitePageId2internalId = {};

                // Map website pages.
                let result = _.map(this.websitePages, websiteMenu => {
                    return {
                        id: 'menu_' + websiteMenu.id,
                        websiteMenu,
                        type: 'menu',
                        name: websiteMenu.name,
                        children: this.mapItem(websiteMenu, websiteMenu.website_menu_pages, ['websiteMenu-' + websiteMenu.id]),
                    };
                });

                // Set internal value by internal ids.
                this.internalValue = this.websitePageId2internalId[this.value] || 0;

                //
                return result;
            },

            selectedWebsitePage()
            {
                if(this.value)
                    return this.id2websitePage[this.value];
            }

        },

    }

</script>

<style lang="scss" scoped>

    .habitats-admin-common-WebsitePageSelector {

        ::v-deep {
            .vue-treeselect__option--selected { background: white !important; }
        }

    }

</style>
