common.View.create('common.form.inputs.Switch', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initGui();
    },

    /**
     * Initializes the GUI.
     */
    initGui() {
        //
        this.element.find('input').each( (key, value) => {
            // On switch change
            $(value).on('change', (event) =>
            {
                // Find trigger
                $('[data-trigger="' + $(event.currentTarget).attr('name') + '"]')

                    // Toggle class
                    .toggleClass('active', $(event.currentTarget).prop('checked') );
            });

        });
    }

});
