common.View.create('habitats.website.partials.Menu', {

    onDOMLoad()
    {
        // Init MMenu?
        if(this.isMmenu())
            this.initMmenu();

    },

    isMmenu()
    {
        return this.element.data('style') == 'mmenu';
    },

    initMmenu()
    {
        // Init MMenu.
        this.element.mmenu({
            extensions: [
                'pagedim-black',
                'position-right'
            ],
        });
    },

});
