/**
 * Creates a object for every .components-form-Daterange DOM element.
 */
common.View.create('habitats.website.modules.cart.Index', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initForm();
    },

    initForm() {
        this.element.find('.cartGrid')
            .find('.actions')
                .on('click', (event) => {
                    this.deleteEvent(event);

                    return false;
                })
                .end()

            .find('.amount form > button')
                .on('click', (event) => {
                    this.onFormActionClick(event);

                    return false;
                })
                .end()

            .end();
    },

    deleteEvent(event)
    {
        $.ajax({
            url: document.location.href + '/' + $(event.currentTarget).data('id'),
            type: 'DELETE',
            success: (data) => {
                // If success
                if (data.success) {
                    let $item = $(event.currentTarget).parents('.item'),
                        $cartGrid = $item.parent();

                    // Delete item
                    $item.remove();

                    // Check if all items are deleted, then refresh page
                    if( $cartGrid.find('.item').length < 1 )
                        window.location.reload();
                }
            }
        })
    },

    onFormActionClick(event)
    {
        // Ajax form submit
        $.post( $(event.currentTarget).parents('form').attr('action'), {
            'amount': $(event.currentTarget).val()
        })
        .done((data) =>  {
            // Not successful?
            if( !data.success ) {
                $('.toastMaxAmount').toast('show');
                return;
            }

            // Get current item
            let $item = $(event.currentTarget).parents('.item'),
                currentValue = +$(event.currentTarget).val();

            // Current value is less than 0; stop function cause you can't order 0 items. User should delete instead of changing amount to 0
            if( currentValue < 0 )
                return;

            // Get price of product and get new price after button click
            let amount = this.parseToNumber( $item.find('.amount form > span').text() ),
                price = this.parseToNumber( $item.find('.price span').text() ),
                priceForOne = price / amount,
                newPrice = priceForOne * currentValue;

            // Update individual price
            $item.find('.price span').text( this.parseNumberToLocale(newPrice) );

            // Update amount
            $item.find('.amount form > span').text( currentValue );

            // Update all prices
            let totalPrice = 0.00,
                totalItems = 0;
            $item.parent().find('.item').each((key, item) => {
                totalPrice += +this.parseToNumber( $(item).find('.price span').text() );
                totalItems += +$(item).find('.amount .current').text();
            });
            $('.habitats-website-modules-cart-Index').find('.total span').text( this.parseNumberToLocale(totalPrice) );

            // Update header amount
            $('.habitats-website-layouts-main-Header').find('.cart .qty').text( totalItems );

            // Change value buttons
            $item.find('.amount form > button.add').val( currentValue + 1 );
            $item.find('.amount form > button.remove').val( currentValue - 1 );

            // Remove amount is 0?
            if( currentValue === 1 ) {
                $item.find('.amount form > button.remove').attr('disabled', true);
            } else {
                $item.find('.amount form > button.remove').attr('disabled', false);
            }
        });
    },

    parseToNumber(value)
    {
        return parseFloat( value.replace(',', '.') ).toFixed(2);
    },

    parseNumberToLocale(value)
    {
        return value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

});