common.View.create('common.form.inputs.Date', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initGui();
    },

    /**
     * Initializes the GUI.
     */
    initGui() {
        let options = {
            minDate: moment(),
            singleDatePicker: true,
            showDropdowns: true,
            locale: {
                format: 'DD-MM-YYYY',
                firstDay: 1
            },
            autoUpdateInput: false
        };

        // Check if data-customDisabledDates is set
        if( this.element.find('input').data('customDisabledDates') ) {
            options = {
                ...options,
                isInvalidDate: function(date) {
                    // Disable all days except Monday, Wednesday, and Friday
                    var day = date.day();
                    var formattedDate = date.format('YYYY-MM-DD');
                    
                    // Disable the date if it's not Monday, Wednesday, or Friday
                    var isDayValid = (day === 1 || day === 3 || day === 5);
                    
                    // Disable the date if it's in the reserved dates array
                    var isDateReserved = reservedDates.includes(formattedDate);
                    
                    return !isDayValid || isDateReserved;
                }
            }
        }

        this.element

            // Single date picker
            .find('.singledate')
                .daterangepicker(options)
                .on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('DD-MM-YYYY'));
                })
                .end()


            // Date range picker
            .find('.multidate')
                .daterangepicker({
                    minDate: moment(),
                    showDropdowns: true,
                    locale: {
                        format: 'DD-MM-YYYY'
                    },
                    autoUpdateInput: false,
                })
                .on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
                })
                .end()

            // Remove date value on cancel (or on load)
            .find('.singledate, .multidate')
                .on('cancel.daterangepicker', () => {
                    $(this).val('');
                })
                .end();

    },

});
