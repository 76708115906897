common.View.create('common.form.Disabled', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.setFieldsDisabled();
    },

    /**
     * Initializes the GUI.
     */
    setFieldsDisabled() {
        $('.form-disabled fieldset')
            .find('input, textarea, select')
            .prop('disabled', true);
    },

});