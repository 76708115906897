common.View.create('common.form.inputs.Richtext', {

    onDOMLoad()
    {
        this.initCkeditor();
    },

    async initCkeditor()
    {
        // Get textarea.
        let textarea = this.element.find('textarea').get(0);

        // Init Ckeditor.
        let editor = await ClassicEditor.create(textarea, this.getOptions());

        // Wait for load.
        this.onEditorLoaded(editor);

        //
        this.initHeight(editor);
    },

    getOptions()
    {
        // Get options
        let customOptions = this.element.find('textarea').data('ckeditor');

        // Init default options.
        let defaultOptions = {
            ckfinder: {
                uploadUrl: '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ]
            },
            mediaEmbed: {
                previewsInData: true,
            },
            toolbar: [ 'ckfinder', '|', 'heading', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'alignment', '|', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'horizontalLine', '|', 'undo', 'redo', '|' ],
            link: {
                decorators: {
                    // @USE? Automatic link redirect
                    // addTargetToExternalLinks: {
                    //     mode: 'automatic',
                    //     callback: url => /^(https?:)?\/\//.test( url ),
                    //     attributes: {
                    //         target: '_blank',
                    //         rel: 'noopener noreferrer'
                    //     }
                    // }
                    addTargetToLinks: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                }
            }
        };

        // Merge config.
        return Object.assign(defaultOptions, customOptions);
    },

    // @TODO @UGLY Ckeditor wont update textarea value?
    onEditorLoaded(editor)
    {
        //
        $('button[type="submit"]')
            .on('click', () =>
            {
                editor.updateSourceElement();
            });

        $('form')
            .on('submit', () =>
            {
                editor.updateSourceElement();
            });

        //
        setInterval(function()
        {
            editor.updateSourceElement();
        }, 500);

        // Set read only
        editor.isReadOnly = !!this.element.find('textarea').attr('disabled');
    },

    initHeight(editor)
    {
        // Abort due to no height given?
        let height = this.element.find('textarea').data('height');

        if(!height)
            return;

        // @ugly Add CSS.
        let labelledby = this.element.find('textarea + .ck').attr('aria-labelledby');

        $('<style>div[aria-labelledby="' + labelledby + '"] .ck-content { min-height: ' + height + 'px; } </style>').appendTo('body');
    }

});
