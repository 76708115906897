common.View.create('common.form.inputs.Maps', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initGui();
    },

    /**
     * Initializes the GUI.
     */
    initGui() {
        // Get google maps api key
        var apiKey = $('#gMapsApiKey').text();

        // Check when sscript is done loading
        $.getScript('https://maps.googleapis.com/maps/api/js?key='+ apiKey +'&libraries=places&', function() {
            // Variables
            var map,
                marker,
                $lat = document.getElementById('lat'),
                $long = document.getElementById('lng'),
                $zoom = document.getElementById('zoom'),
                myLatLng,
                service,
                infowindow;

            // Settings
            myLatLng = {lat: parseFloat($lat.value), lng: parseFloat($long.value)};
            infowindow = new google.maps.InfoWindow();

            // Get map
            map = new google.maps.Map(document.getElementsByClassName('map')[0], {
                zoom: 4,
                center: myLatLng
            });

            // Set marker
            marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                draggable: true,
                title: 'Versleep me!'
            });

            // Search places
            // service = new google.maps.places.PlacesService(map);
            // service.findPlaceFromQuery(request, function (results, status) {
            //     if (status === google.maps.places.PlacesServiceStatus.OK) {
            //         for (var i = 0; i < results.length; i++) {
            //             createMarker(results[i]);
            //         }
            //
            //         map.setCenter(results[0].geometry.location);
            //     }
            // });

            // Add listener
            // google.maps.event.addListener(marker, 'click', function (event) {
            //     alert(this.position);
            // });

            // Double click add marker
            google.maps.event.addListener(map, 'dblclick', function (event) {
                // Change marker position
                var positionDoubleclick = event.latLng;
                marker.setPosition(positionDoubleclick);

                // Set lat and lng values on hidden inputs
                $lat.value = event.latLng.lat();
                $long.value = event.latLng.lng();
            });

            // Set the coordinates to the gogole maps to a hidden field
            google.maps.event.addListener(marker, 'dragend', function (event) {
                // Set lat and lng values on hidden inputs
                $lat.value = event.latLng.lat();
                $long.value = event.latLng.lng();
            });

            google.maps.event.addListener(map, 'zoom_changed', function () {
                $zoom.value = map.getZoom();
            });

        });

    }

});