import AutoloadViews from './../common/AutoloadViews';

import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

//
require('../common/sentry/withoutVue');

// Init Lodash.
window._ = require('lodash');

// Add CSRF token to jQuery Ajax.
$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')
	}
});

// Init Moment.
window.moment = require('moment');
window.moment.locale('nl');

// Init View class.
require('../common/View.js');

require('./website/layouts/main.js');
// Init the required common tools for this habitat.
// require('../common/vue.js');
require('../common/index.js');

// Auto load views.
AutoloadViews.load('common.canvas', require.context('./../../components/common/canvas/', true, /\/block.(js|vue)$/));
AutoloadViews.load('common.form', require.context('./../common/form/', true, /.(js|vue)$/));

AutoloadViews.load('habitats.website', require.context('./website/', true, /.(js|vue)$/));
AutoloadViews.load('habitats.website', require.context('./../../components/habitats/website/', true, /.(js|vue)$/));
