/**
 * Creates a object for every .components-form-Time DOM element.
 */
common.View.create('common.form.inputs.Url', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initSubmitValue();
    },

    initSubmitValue() {
        this.element.parents('form')
            .on('submit', (event) => {
                // For each url field; using this since you can only prevent submit once. Otherwise it will not change all input values
                $('.common-form-inputs-Url').each((key, item) => {
                    // Change value
                    let value = $(item).find('datalist option[value="' + $(item).find('input').val() + '"]').data('value');

                    // If value is found; then send this to form instead
                    if( value )
                        $(item).find('input').val( value );
                });

                // Submit now
                $(event.currentTarget).submit();

                // Prevent submit; this is so the code can be run
                // The above ^ code will submit form after changing the value
                return false;
            });
    }

});