common.View.create('common.form.inputs.Colorpicker', {

    onDOMLoad()
    {
        this.initColorPicker();
    },

    initColorPicker()
    {
        // Simple example, see optional options for more configuration.
        const pickr = Pickr.create({
            el: this.element.find('input[type="text"]').get(0),
            theme: 'classic', // or 'monolith', or 'nano'

            useAsButton: false,

            default: this.element.find('input[type="text"]').data('default-color'),

            components: {

                // Main components
                preview: true,
                opacity: true,
                hue: true,

                // Input / output Options
                interaction: {
                    hex: true,
                    input: true,
                    clear: true,
                    save: true
                }
            },

            lockOpacity: true,

            strings: {
                save: 'Opslaan',  // Default for save button
                clear: 'Legen', // Default for clear button
                cancel: 'Annuleren' // Default for cancel button
            },

        }).on('save', (color, instance) => {
            // Update hidden field.
            this.element.find('input[type="hidden"]').val(color.toHEXA().toString());
        });
    },

});
