/**
 * Creates a object for every .Users DOM element.
 */
common.View.create('habitats.website.modules.cart.Show', {

    onDOMLoad()
    {
        this.initChangeShippingMethod();
        this.initChangeAlternateShippingAddress();
    },

    initChangeShippingMethod()
    {
        this.element

            // Listen for shipping method change.
            .find('input[name="shipping"]')
                .on('change', (event) => {

                    this.element
                        .find('[data-shipping]')
                            .addClass('d-none')
                            .end()
                        .find('[data-shipping="' + event.currentTarget.value + '"]')
                            .removeClass('d-none')
                            .end();

                }).trigger('change')
                .end();
    },

    initChangeAlternateShippingAddress()
    {
        this.element

            // Listen for alternate delivery address change.
            .find('input[name="alternate_delivery_address"]')
                .on('change', (event) => {
                    if($(event.currentTarget).is(':checked')) {
                        this.element.find('[data-alternate-delivery-address]').removeClass('d-none');
                    }
                    else {
                        this.element.find('[data-alternate-delivery-address]').addClass('d-none');
                    }

                })
                .end();
    }

});
