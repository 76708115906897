common.View.create('common.canvas.blocks.custom.images.Block', {

    onDOMLoad() {
        this.initLightGallery();
    },

    initLightGallery() {
        let element = this.element.find('#imagesGrid');

        if (element.data('enabled') === false) {
            return;
        }

        lightGallery(element);
    },

});
