common.View.create('common.form.inputs.Time', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initGui();
    },

    /**
     * Initializes the GUI.
     */
    initGui() {
        // Make checkbox a switch
        this.element.find('.clockpicker')
            .clockpicker({
                donetext: 'Done'
            });
    }

});