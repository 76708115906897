<template>

    <div class="common-form-inputs-Image"
         :class="{ dragHover }"
         v-on:drop="onDrop"
         v-on:dragover="onDragOver"
         v-on:dragleave="onDragLeave">

        <img
                v-if="imageUrl && !uploadCancellationToken"
                :src="imageUrl"
                class="mb-3 shadow-md">

        <br v-if="imageUrl" />

        <label
                class="border-dashed border-gray-400 h-32 bg-gray-100 text-gray-500 hover:text-gray-600 text-center text-sm flex cursor-pointer p-0 dropzone"
                ref="dropzone"
                v-show="!imageUrl || uploadCancellationToken"
                @click="uploadCancellationToken ? cancelUpload($event) : null">

            <div class="m-auto px-2">

                <template v-if="uploadCancellationToken && uploadPercentage < 100">

                    <div class="rounded bg-gray-200 mb-2">
                        <div class="rounded bg-green-500 h-2" :style="{ width: uploadPercentage + '%' }"></div>
                    </div>

                    Bezig met uploaden...<br />
                    <span class="text-xs text-gray-400">Klik om te annuleren.</span>

                </template>

                <template v-else-if="uploadCancellationToken && uploadPercentage == 100">

                    <i class="fa fa-circle-notch text-2xl fa-spin"></i>
                    <div class="mt-2">
                        Bezig met optimaliseren..<br />
                        Klik om te annuleren.
                    </div>

                </template>

                <template v-else>

                    <i class="fa fa-upload text-2xl"></i>
                    <div class="mt-2">
                        Drag 'n drop hier een afbeelding
                        <br />of klik hier.
                    </div>

                </template>

            </div>

            <input class="hidden" type="file" @change="onChange">

        </label>

        <button
                v-if="!uploadCancellationToken && imageUrl"
                class="btn btn-outline-danger btn-sm rounded-full px-4 py-2 mt-1 mb-2"
                type="button"
                @click="removeImage">
            <i class="fa fa-trash mr-1"></i> Verwijder
        </button>

        <avatar-cropper
                v-if="dropzoneRefPresent && hasCrop"
                ref="avatarCropper"
                :uploadHandler="cropUploadHandler"
                :labels="{
                    submit: 'Gereed',
                    cancel: 'Annuleer'
                }"
                :trigger="$refs['dropzone']"
                :outputOptions="outputOptions"
                :cropperOptions="cropperOptions" />

        <input ref="input" type="hidden" :name="inputName" />

    </div>

</template>

<script>

    import axios from 'axios';
    import AvatarCropper from "vue-avatar-cropper";
    import {readAndCompressImage} from 'browser-image-resizer';

    export default {

        components: {
            AvatarCropper,
        },

        props: {
            inputName: String,
            existingMediaId: String,
            existingMediaUrl: String,
            initialValue: String,
            outputOptions: Object,
        },

        data()
        {
            return {
                imageUrl: null,
                name: String,
                mounted: false,
                dropzoneRefPresent: false,

                uploadCancellationToken: false,
                uploadPercentage: null,

                dragHover: false,
            };
        },

        mounted()
        {
            this.initInitialValue();
            this.initImageUrl();

            // @ugly Otherwise avatar croppen element wont find dropzone ref.
            this.dropzoneRefPresent = true;
        },

        methods: {

            initInitialValue()
            {
                // Set input value.
                this.$refs['input'].value = this.initialValue;
            },

            initImageUrl()
            {
                // Abort due to no initial value?
                if(!this.initialValue)
                    return;

                // Is existing media?
                if(parseInt(this.initialValue) == this.initialValue)
                    this.imageUrl = this.existingMediaUrl;

                // Is temp file?
                else
                    this.imageUrl = '/common/form/media/temp?tmpFile=' + encodeURIComponent(this.initialValue);
            },

            onDragOver(event)
            {
                event.preventDefault();

                this.dragHover = true;
            },

            onDragLeave()
            {
                this.dragHover = false;
            },

            async onDrop(event)
            {
                // Stop event.
                event.stopPropagation();
                event.preventDefault();

                //
                this.dragHover = false;

                // Get file.
                let file = _.first(event.dataTransfer?.files || []);

                if(!file)
                    return;

                // Delegate
                if( this.outputOptions )
                    await this.onCropNewFile(file);

                else
                    await this.onFile(file);
            },

            async onCropNewFile(file)
            {
                let avatarCropper = this.$refs['avatarCropper'];

                // @note Taken from avatar cropper itself.
                let reader = new FileReader()
                reader.onload = e => {
                    avatarCropper.dataUrl = e.target.result
                }
                reader.readAsDataURL(file)

                avatarCropper.filename = file.name || 'unknown'
                avatarCropper.mimeType = avatarCropper.mimeType || file.type
                avatarCropper.$emit('changed', file, reader)
            },

            async onChange(event)
            {
                await this.upload(event.target.files[0]);
            },

            async onFile(file)
            {
                await this.upload(file);
            },

            async upload(file)
            {
                // Abort due to no file selected?
                if(!file || !file.type)
                    return;

                // Notify user due to not an image?
                if (!file.type.match('image.*'))
                    await Swal.fire({
                        title: 'Ongeldig bestand',
                        text: 'Dit bestand is geen afbeelding. Enkel afbeeldingen zijn toegestaan!',
                        type: 'warning',
                    });

                await this.uploadToServer(file);

                // Show
                this.imageUrl = (window.URL || window.webkitURL).createObjectURL(file);
            },

            cropUploadHandler(resp)
            {
                // Crop
                this.$refs['avatarCropper'].cropper.getCroppedCanvas(this.outputOptions).toBlob(async blob =>
                {
                    // Set image url.
                    this.imageUrl = (window.URL || window.webkitURL).createObjectURL(blob);

                    // Refresh due to uploaded succesfully?
                    await this.uploadToServer(blob);
                }, this.outputOptions?.mime || 'image/jpeg');
            },

            async uploadToServer(file)
            {
                // Init form data.
                var formData = new FormData();

                formData.append('file', file);

                // Init cancellation token.
                this.uploadCancellationToken = axios.CancelToken.source();

                // Reset upload percentage.
                this.uploadPercentage = 0;

                try {
                    // Request
                    let response = await axios.post('/common/form/media/upload', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        cancelToken: this.uploadCancellationToken.token,
                        onUploadProgress: progressEvent => {
                            // Calculate and set upload percentage.
                            this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        },
                    });

                    // Set tmp file.
                    this.$refs['input'].value = response.data.tmpFile;

                    //
                    return true;
                }
                catch(e)
                {
                    // Is cancel?
                    if(axios.isCancel(e))
                        return false;

                    // Notify user.
                    this.$toasted.show('<i class="far fa-exclamation-triangle"></i> Fout bij verwerken afbeelding. Probeer het (later) opnieuw.', { type: 'error'  });

                    //
                    throw e;
                }
                finally
                {
                    // Mark as done.
                    this.uploadCancellationToken = null;
                    this.uploadPercentage = null;
                }
            },

            cancelUpload(event)
            {
                // Stop event to prevent opening file chooser.
                event.stopPropagation();
                event.preventDefault();

                // Cancel
                this.uploadCancellationToken.cancel();
                this.uploadCancellationToken = null;
            },

            async removeImage()
            {
                // Remove media.
                this.imageUrl = null;

                this.$refs['input'].value = null;
            },

        },

        computed: {

            // Not reactive.
            hasMedia()
            {
                return this.editorComponent.hasMedia;
            },
            hasCrop()
            {
                return !!this.outputOptions;
            },
            cropperOptions()
            {
                return {
                    aspectRatio: this.outputOptions.width / this.outputOptions.height,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: false,
                    zoomable: true,
                };
            },

        },

    }

</script>

<style lang="scss" scoped>

    // TODO Remove the use of Tailwind in the template, since it is not loaded.

    .common-form-inputs-Image {
        font-size: 0;
        transition: opacity .2s;

        &.dragHover {
            opacity: .5;
        }

        img {
            max-height: 300px;
            max-width: 500px;
        }

        .dropzone {
            background: #fdfdfd;
            border: 1px dashed #cbd5e0;
        }

        &::v-deep {

            .avatar-cropper .avatar-cropper-mark {
                background: rgba(0, 0, 0, .15);
            }

            .avatar-cropper-mark {
                backdrop-filter: blur(3px);
            }

            .avatar-cropper-image-container {
                height: 100%;
                max-height: 70vh !important;
                max-width: 70vw !important;
                width: 100%;
            }

            .avatar-cropper-btn {
                font-size: 14px;

                &:hover {

                    &:first-child {
                        background: #009efb !important;
                    }

                    &:last-child {
                        background: #55ce63 !important;
                    }

                }

            }

        }
    }

</style>