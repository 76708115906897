common.View.create('common.form.Index', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.manageTabErrors();
    },

    /**
     * Initializes the GUI.
     */
    manageTabErrors() {

        // Abort due to no tabs present?
        if(!this.element.find('.nav-tabs').length)
            return;

        this.element

            // Loop tabs.
            .find('.nav-tabs li.nav-item')
                .each(this.manageTabItemErrors.bind(this))
                .end();

    },

    manageTabItemErrors(index, tabItemElement)
    {
        // Get related tab panel.
        let tabContent = $(tabItemElement).closest('.nav-tabs').next('.tab-content');
        let tabPanel = tabContent.find('> .tab-pane').eq(index);

        // Determine if has errors.
        let hasInputWithErrors = tabPanel.find('.has-error').length > 0;

        // Show/hide errors on tab.
        $(tabItemElement).toggleClass('has-error', hasInputWithErrors);

        // Determine if required fields are not filled in
        tabPanel.find('input:required')
            .on('invalid', (event) => {
                // If required field doesn't have a value
                $(event.currentTarget).parents('.form-group').addClass('has-error');

                // Show/hide errors on tab.
                $(tabItemElement).addClass('has-error');
            });
    }

});