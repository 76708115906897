common.View.create('habitats.website.partials.FlashMessages', {

    onDOMLoad()
    {
        // Scroll due to is first?
        if(this.isFirst())
            this.scroll();
    },

    /**
     * Determines if this is the first FlashMessages instance on the page.
     *
     * @returns {boolean}
     */
    isFirst()
    {
        return $(this._cssNs()).get(0) == this.element.get(0);
    },

    scroll()
    {
        $('html, body')
            .animate({
                scrollTop: this.element.position().top - 200,
            }, 'slow');
    }

});
